<template>
  <div class="gotop-com" @click="gotop" v-show="isvisible">
  <img src="../assets/gotop.png" alt="">
  </div>
</template>

<script>
export default {
  data() {
    return {
    isvisible:false
    };
  },
    mounted(){
        window.addEventListener('scroll', this.handleScroll, true);
      
    },
  methods: {
      handleScroll(){
        let scrollHeight = document.documentElement.scrollTop;
        if(scrollHeight>100){
            this.isvisible=true
        }else{
            this.isvisible=false

        }
      },
  gotop(){
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
    })
  }
  }
};
</script>

<style lang="scss" scoped>
.gotop-com {
  position: fixed;
  right: 30px;
  bottom:200px;
  z-index: 999;
   width:24px;
     height: 21px;
 img{
     width:24px;
     height: 21px;
 }
}
</style>