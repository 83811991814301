<template>
  <div class="home-page">
    <myHead :stylehead="stylehead"></myHead>
    <div class="card cardpolicy">
      <div class="policycontent">
        <div class="title">Política de Privacidad</div>
        <div>En Prestela, valoramos enormemente la protección de la privacidad de nuestros usuarios. Por esta razón, nuestra Política de Privacidad (en adelante, la "Política") tiene el propósito de informarle sobre cómo manejamos y resguardamos la información y los datos personales de aquellos que utilizan nuestra aplicación y/o sitio web (en adelante, la "Plataforma"), la cual es propiedad de Prestela. Le recomendamos revisar detenidamente esta Política de Privacidad antes de utilizar nuestros servicios. Al acceder a nuestra Plataforma o utilizar cualquiera de nuestros servicios (el "Servicio"), usted acepta los términos descritos en esta Política. Si no está de acuerdo con esta Política de Privacidad, le sugerimos que no utilice nuestros servicios.</div>
        <div>&nbsp;</div>
        <div>Nuestra Responsabilidad en Relación a los Datos Personales: En Prestela, cumplimos de manera estricta con la legislación vigente en lo que respecta a la captura, almacenamiento, divulgación y uso de sus datos personales. Esto incluye aspectos como el acceso, la administración, la transmisión y la eliminación de estos datos.</div>
        <div>&nbsp;</div>
        <div class="title1">1.Solicitudes de Autorización </div>
        <div>Con el propósito de mejorar nuestros servicios y brindarle la mejor experiencia posible, solicitamos su consentimiento para la recolección y utilización de ciertos datos personales. Estos datos son gestionados de manera responsable y protegidos a través de procesos seguros de transmisión a nuestros servidores:</div>
        <div class="indent">SMS: Recopilamos mensajes SMS, pero únicamente procesamos aquellos relacionados con información financiera relevante. Esta información es fundamental para evaluar su situación financiera y determinar su puntuación de crédito, lo que nos permite ofrecerle los mejores préstamos disponibles. Los datos se transmiten de manera segura a través de <b style="color: red">https://services.prestela.one</b>.</div>
        <div class="indent">Registros de Llamadas: Requerimos acceso a su registro de llamadas para verificar la instalación de la aplicación en su dispositivo y proporcionarle una verificación segura de contraseña. Los datos se transmiten de manera segura a través de <b style="color: red">https://services.prestela.one</b>.</div>
        <div class="indent">Contactos de Emergencia: Solicitamos sus 2 contactos de emergencia para verificar su identidad y gestionar riesgos. Los datos se transmiten de manera segura a través de <b style="color: red">https://services.prestela.one</b>.</div>
        <div class="indent">Información de Contacto: Recopilamos y monitoreamos su información de contacto, incluyendo su nombre, número de teléfono y otros detalles. Esto se utiliza para la verificación de registro y para enriquecer su perfil financiero. Los datos se transmiten de manera segura a través de <b style="color: red">https://services.prestela.one</b>.</div>
        <div class="indent">Cámara: Utilizamos la cámara para verificar su identidad y prevenir el robo de información.</div>
        <div class="indent">Ubicación Aproximada: Recolectamos información de ubicación para garantizar que se encuentra dentro del territorio correspondiente para utilizar nuestros servicios. Los datos se transmiten de manera segura a través de <b style="color: red">https://services.prestela.one</b>.</div>
        <div class="indent">Lista de Instalación de la Aplicación: Recopilamos información sobre las aplicaciones instaladas en su dispositivo para evaluar su capacidad de pago y reducir el riesgo crediticio. Los datos se transmiten de manera segura a través de <b style="color: red">https://services.prestela.one</b>.</div>
        <div class="indent">Datos del Portapapeles: Recopilamos datos del portapapeles relacionados con transacciones financieras para evaluar su solvencia y prevenir el fraude. Los datos se transmiten de manera segura a través de <b style="color: red">https://services.prestela.one</b>.</div>
        <div class="indent">Datos del Dispositivo: Recopilamos información específica sobre su dispositivo para identificarlo y prevenir el fraude. Los datos se transmiten de manera segura a través de <b style="color: red">https://services.prestela.one</b>.</div>
        <div>&nbsp;</div>
        <div class="title1">3.Protección de la Información</div>
        <div>Aplicamos rigurosas medidas de seguridad físicas, administrativas y técnicas para proteger su información personal. Utilizamos la encriptación para mantener la privacidad de sus datos y aseguramos una transmisión segura a nuestro servidor.</div>
        <div>&nbsp;</div>
        <div class="title1">4.Cookies</div>
        <div>Nuestra aplicación puede utilizar cookies con el fin de mejorar nuestros servicios. Usted tiene la opción de aceptar o rechazar estas cookies según su preferencia.</div>
        <div>&nbsp;</div>
        <div class="title1">5.Proveedores de Servicios</div>
        <div>Colaboramos con terceros para facilitar y mejorar nuestros servicios. Estos terceros tienen acceso a sus Datos Personales, pero están comprometidos a salvaguardar su privacidad.</div>
        <div>&nbsp;</div>
        <div class="title1">6.Contacto con Nosotros</div>
        <div>Si tiene alguna pregunta o sugerencia relacionada con nuestra Política de Privacidad, no dude en ponerse en contacto con nosotros a través de los siguientes medios:</div>
        <div>&nbsp;</div>
        <div class="indent">Correo Electrónico: <b>support@prestela.one</b></div>
        <div class="indent">Horario de Oficina: De lunes a sábado, de 8:30 a 17:00</div>
        <div class="indent">Página Web de la Empresa: <b>https://www.prestela.one</b></div>
        <div>&nbsp;</div>
        <div>Esta Política de Privacidad comienza a regir a partir de su aceptación y está sujeta a revisiones periódicas. Le aconsejamos que la consulte con regularidad para mantenerse al tanto de cómo resguardamos su privacidad.</div>
      </div>
    </div>
    <myFoot :positive="positive" />
    <goTop />
  </div>
</template>

<script>
import myHead from "@/components/header";
import myFoot from "@/components/footer";
import goTop from "@/components/goTop";
export default {
  data() {
    return {
      stylehead: {
        color: "#FFFFFF",
        background: "#000",
      },
      positive: "absolute",
    };
  },

  methods: {},
  components: {
    myHead,
    myFoot,
    goTop,
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  position: relative;
  padding-bottom: 300px;
  .cardpolicy {
    padding: 20px 0;
    background: #f6f6f6;
    padding-top: 100px;
    .policycontent {
      width: 80%;
      margin: 0 auto;
      .title {
        text-align: center;
        font-weight: bold;
        font-size: 24px;
        margin: 40px 0;
      }
      .title1 {
        font-weight: bold;
        font-size: 22px;
        margin: 10px 0;
      }
      p, div {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1a0404;
        line-height: 34px;
        letter-spacing: 1px;
      }
    }
  }
}
</style>
