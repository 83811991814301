<template>
  <div class="footer-com" :style="{ position: positive }">
    <div class="left">
      <div class="item-logo">Prestela</div>
      <!-- <img src="../assets/logo.png" class="item-logo" alt="" /> -->
    </div>
    <div class="middle">
      <p>
        <img src="../assets/email.png" alt="" />
        <span>support@prestela.one</span>
      </p>
      <p class="title">
        <span>Servicio al Cliente: +56 996927778</span>
      </p>
    </div>
    <div class="right">
      <p class="title">Móvil: +56 996927778</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["positive"],
  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.footer-com {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 999;
  height: 251px;
  background: #080808;
  display: flex;
  justify-content: space-between;
  .left {
    width: 25%;
    text-align: center;
    .item-logo {
      color: #ffffff;
      width: 200px;
      font-size: 28px;
      // height: 10px;
      margin: 40px 0 0 40px;
    }
  }
  .middle {
    width: 30%;
    padding-top: 40px;
    p {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      img {
        width: 23px;
      }
      span {
        margin-left: 10px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
      }
    }
    .title {
      margin-left: 10px;
      line-height: 37px;

      span {
        font-size: 20px;
        font-family: PingFangSC-bold, PingFang SC;
      }
    }
  }
  .right {
    width: 33%;
    padding-top: 40px;
    p {
      width: 80%;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 37px;
      text-align: center;
    }
    .title {
      font-size: 20px;
      font-family: PingFangSC-bold, PingFang SC;
    }
  }
}
</style>