<template>
  <div class="home-page">
    <myHead :stylehead="stylehead"></myHead>
    <div class="card cardpolicy">
      <div class="policycontent">
        <div class="title">Contrato de Usuario</div>
        <div class="title1">Bienvenido al servicio de préstamos de Prestela. Antes de utilizar este servicio, lea detenidamente los siguientes términos y condiciones para asegurarse de que los entiende y acepta. Si no está de acuerdo con alguno de los términos de este contrato, le pedimos que no utilice el servicio de Prestela.</div>
        <div>&nbsp;</div>
        <div class="title1">I. Aceptación de los Términos</div>
        <div>1.1 Al descargar, instalar, registrarse, acceder o utilizar de cualquier otra forma el servicio de préstamos de Prestela, usted está indicando que ha leído, comprendido y aceptado cumplir con los términos y condiciones de este contrato, así como con las políticas relacionadas. Si no está de acuerdo con alguno de los términos de este contrato, le rogamos que no utilice el servicio.</div>
        <div>1.2 El contenido de este contrato puede cambiar en cualquier momento. Las modificaciones entrarán en vigor tan pronto como se publiquen en el servicio de Prestela, y sustituirán automáticamente las disposiciones anteriores. Le sugerimos que consulte el contenido más reciente de este contrato en la aplicación o sitio web de Prestela. El hecho de seguir utilizando el servicio de Prestela se considerará como una aceptación de las versiones más recientes de los términos y condiciones.</div>
        <div>&nbsp;</div>
        <div class="title1">II. Contenido del Servicio</div>
        <div>2.1 El servicio de préstamos de Prestela es una aplicación de software que brinda a los usuarios servicios de préstamos y evaluación crediticia, así como otros servicios financieros relacionados.</div>
        <div>2.2 Al utilizar el servicio de Prestela, es posible que deba proporcionar información personal. Usted se compromete a brindar información veraz, precisa y completa. En caso contrario, podría ser responsable legalmente.</div>
        <div>2.3 Prestela realizará evaluaciones crediticias basadas en la información proporcionada por los usuarios para determinar si son elegibles para recibir préstamos.</div>
        <div>&nbsp;</div>
        <div class="title1">III. Derechos y Obligaciones del Usuario</div>
        <div>3.1 Al utilizar el servicio de Prestela, el usuario se compromete a cumplir con las leyes y regulaciones vigentes, y a no utilizar el servicio para actividades ilegales o contrarias al orden público.</div>
        <div>3.2 El usuario debe mantener la confidencialidad de su información de cuenta y contraseña. El usuario será responsable de cualquier pérdida causada por la divulgación o pérdida de esta información.</div>
        <div>3.3 El usuario no podrá utilizar el servicio de Prestela para infringir los derechos legales de terceros, incluidos, entre otros, los derechos de propiedad intelectual y la privacidad.</div>
        <div>&nbsp;</div>
        <div class="title1">IV. Protección de la Privacidad</div>
        <div>4.1 Prestela tomará medidas razonables para proteger la seguridad de la información personal del usuario. La información personal no se compartirá con terceros sin el consentimiento del usuario, excepto cuando lo exijan las leyes y regulaciones aplicables o cuando el usuario lo autorice expresamente.</div>
        <div>4.2 El usuario comprende y acepta que Prestela puede divulgar su información personal basándose en requerimientos legales, decisiones judiciales, solicitudes de agencias gubernamentales u otras circunstancias legalmente permitidas.</div>
        <div>&nbsp;</div>
        <div class="title1">V. Cambios y Terminación del Servicio</div>
        <div>5.1 Prestela se reserva el derecho de cambiar, suspender o terminar parcial o totalmente los contenidos del servicio en cualquier momento, sin previo aviso.</div>
        <div>5.2 Si el usuario viola este contrato o participa en actividades ilegales o irregulares al utilizar el servicio de Prestela, Prestela se reserva el derecho de suspender o cancelar los servicios al usuario y de tomar las medidas legales correspondientes.</div>
        <div>&nbsp;</div>
        <div class="title1">VI. Descargo de Responsabilidad</div>
        <div>6.1 El usuario comprende y acepta que utiliza el servicio de Prestela bajo su propio riesgo.</div>
        <div>6.2 Prestela no se responsabiliza por las pérdidas derivadas de fallas técnicas, interrupciones inesperadas, pérdida o daño de información, ataques de hackers, uso indebido del usuario, entre otros factores.</div>
        <div>&nbsp;</div>
        <div class="title1">VII. Propiedad Intelectual</div>
        <div>7.1 Todo el contenido, información y tecnología incluidos en el servicio de Prestela son propiedad exclusiva de Prestela y están protegidos por las leyes de propiedad intelectual.</div>
        <div>7.2 El usuario no podrá copiar, difundir, mostrar, modificar, reproducir, vender ni utilizar de ninguna manera el contenido, la información o la tecnología de Prestela sin el consentimiento previo y por escrito de Prestela.</div>
        <div>&nbsp;</div>
        <div class="title1">VIII. Otras Disposiciones</div>
        <div>8.1 Este contrato se rige por las leyes y regulaciones de la República del Perú.</div>
        <div>8.2 Si alguna disposición de este contrato es inválida o inaplicable, esto no afectará la validez de las demás disposiciones.</div>
        <div>8.3 La interpretación, ejecución y resolución de controversias relacionadas con este contrato se regirán por las leyes.</div>
        <div>&nbsp;</div>
        <div class="title1">IX. Información de Contacto</div>
        <div>Si tiene alguna pregunta, no dude en ponerse en contacto con nosotros:</div>
        <div>&nbsp;</div>
        <div>Correo electrónico: <b>support@prestela.one</b></div>
        <div>&nbsp;</div>
        <div>Por favor, lea y comprenda estos términos y condiciones antes de utilizar el servicio de Prestela. Al utilizar nuestro servicio, usted acepta estar sujeto a estos términos.</div>
      </div>
    </div>
    <myFoot :positive="positive" />
    <goTop />
  </div>
</template>

<script>
import myHead from "@/components/header";
import myFoot from "@/components/footer";
import goTop from "@/components/goTop";
export default {
  data() {
    return {
      stylehead: {
        color: "#FFFFFF",
        background: "#000",
      },
      positive: "absolute",
    };
  },

  methods: {},
  components: {
    myHead,
    myFoot,
    goTop,
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  position: relative;
  padding-bottom: 300px;
  .cardpolicy {
    padding: 20px 0;
    background: #f6f6f6;
    padding-top: 100px;
    .policycontent {
      width: 80%;
      margin: 0 auto;
      .title {
        text-align: center;
        font-weight: bold;
        font-size: 24px;
        margin: 40px 0;
      }
      .title1 {
        font-weight: bold;
        font-size: 22px;
        margin: 10px 0;
      }
      p, div {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1a0404;
        line-height: 34px;
        letter-spacing: 1px;
      }
    }
  }
}
</style>