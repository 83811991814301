<template>
  <div class="home-page">
    <myHead :stylehead="stylehead"></myHead>
    <section class="time-section">
      <img class="img" width="100%" src="../assets/homebg.png" />
      <h4 class="sub-title">
        Insistir en centrarse en aplicaciones financieras y servicios de operación publicitaria.
      </h4>
      <!-- <div class="more-icon">
        <a href="#two"></a>
      </div> -->
      <!-- <div class="bottom-box">
        <img src="../assets/bottom.png" alt="">
      </div> -->
    </section>
    <div class="body">
      <div class="products">
        <div class="title">
          <div class="line"></div>
          <div class="txt">PRODUCTOS</div>
          <div class="line"></div>
        </div>
        <div class="info">
          <div class="txt">Servicio general</div>
          <div class="line"></div>
          <div class="txt">Promoción de marca</div>
          <div class="line"></div>
          <div class="txt">Publicidad</div>
        </div>
        <div class="items">
          <div class="itembox">
            <div class="img">
              <img src="../assets/openaction.png" alt="">
            </div>
            <div class="tit">
              <b>MANUAL DE OPERACIONES</b>
            </div>
            <div class="txt">Proporcionar servicios de ejecución de entrega y consultores de proceso completo para responder preguntas.</div>
          </div>
          <div class="itembox">
            <div class="img">
              <img src="../assets/advertising.png" alt="">
            </div>
            <div class="tit">
              <b>MEDIO DE PUBLICIDAD</b>
            </div>
            <div class="txt">Recopile tráfico global de alta calidad y personalice la mejor combinación de medios</div>
          </div>
          <div class="itembox">
            <div class="img">
              <img src="../assets/customer.png" alt="">
            </div>
            <div class="tit">
              <b>SERVICIO AL CLIENTE</b>
            </div>
            <div class="txt">8:30 a 17:00 expertos en atención al cliente online, siempre dispuestos a resolver los problemas de los clientes</div>
          </div>
        </div>
        <div class="items">
          <div class="itembox">
            <div class="img">
              <img src="../assets/creative.png" alt="">
            </div>
            <div class="tit">
              <b>SERVICIO CREATIVO</b>
            </div>
            <div class="txt">Proporcionar servicios de ejecución de entrega y consultores de proceso completo para responder preguntas.</div>
          </div>
          <div class="itembox">
            <div class="img">
              <img src="../assets/brand.png" alt="">
            </div>
            <div class="tit">
              <b>LANZAMIENTO DE MARCA</b>
            </div>
            <div class="txt">Un equipo sólido se especializa en brindar publicidad integral de marca y publicación de juegos.</div>
          </div>
          <div class="itembox">
            <div class="img">
              <img src="../assets/data.png" alt="">
            </div>
            <div class="tit">
              <b>SERVICIO DE DATOS</b>
            </div>
            <div class="txt">El marketing inteligente potencia la toma de decisiones precisa y eficiente</div>
          </div>
        </div>
      </div>
    </div>
    <myFoot />
    <goTop />
  </div>
</template>

<script>
import myHead from "@/components/header";
import myFoot from "@/components/footer";
import goTop from "@/components/goTop";

export default {
  data() {
    return {
      stylehead: {
        color: "#FFFFFF",
        background: "transparent",
      },
      positive: "absolute",
    };
  },
  created() {
    console.log(this.language);
  },
  methods: {
    changeLanguage(lang) {
      this.language = lang;
    },
  },
  components: {
    myHead,
    myFoot,
    goTop,
  },
};
</script>

<style lang="scss" scoped>
@keyframes bounce-down {
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
.home-page {
  position: relative;
  padding-bottom: 300px;

  .time-section {
    position: relative;
    .img {
      display: block;
      max-width: 100%;
      border: none;
      outline: none;
    }
    .sub-title {
      width: 70%;
      position: absolute;
      top: 45%;
      left: 50%;
      font-size: 30px;
      line-height: 40px;
      color: #fff;
      display: flex;
      justify-content: space-around;
      align-items: center;
      transform: translateX(-50%);
      text-align: center;
      .home-line {
        display: inline-block;
        width: 70px;
        height: 3px;
        background: rgba(255, 255, 255, 0.5);
      }
      img {
        width: 75%;
      }
    }
    .bottom-box {
      position: absolute;
      left: 50%;
      bottom: 50px;
      transform: translateX(-50%);
      img {
        width: 88px;
        height: 44px;
      }
    }
  }
  .body {
    width: 100%;
    background: #fff;
    
    .products {
      width: 85%;
      padding: 50px 0px;
      margin: 0 auto;

      .title {
        display: flex;
        justify-content: center;
        align-items: center;

        .txt {
          font-size: 32px;
          font-weight: 300;
          margin: 0 10px;
          color: #1A0404;
        }
        .line {
          width: 1px;
          height: 32px;
          background: #1A0404;
        }
      }
      .info {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;

        .txt {
          font-size: 20px;
          color: #B0B0B0;
          margin: 0 10px;
        }

        .line {
          width: 1px;
          height: 20px;
          background: #B0B0B0;
        }
      }
      
      .items {
        display: flex;
        justify-content: space-between;
        margin-top: 70px;

        .itembox {
          width: 300px;
          
          .img {
            width: 200px;
            height: 200px;
            border: 1px solid #7014FF;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin: 0 auto;

            img {
              width: 78px;
            }
          }
          .tit {
            margin-top: 30px;
            text-align: center;
            font-size: 24px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #565353;
            line-height: 33px;
          }
          .txt {
            text-align: center;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #565353;
            line-height: 28px;
            letter-spacing: 1px;
            margin-top: 5px;
          }
        }
      }
    }
  }
}
</style>
